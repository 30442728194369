<template>
	<div>
    <div class="flex justify-between py-2">
      <a-button
          v-if="is_operator"
          type="primary"
          class="default-button mr-1 base_text flex justify-center items-center"
          size="small"
          @click="add_request">
        <a-icon type="plus" style="font-size: large; margin-right: 0px; width: 20px; height: 20px" />
        <div class="ml-2">
          {{ $t('add_request_text') }}
        </div>
      </a-button>
    </div>
		<div id="kanban" class="kanban-page">

			<draggable :list="GET_STATUSES" :animation="200" class="kanban-boards" ghost-class="ghost-card" group="boards" style="overflow-x: auto">
				<KanbanBoard
					v-for="(board, index) in GET_STATUSES"
					:key="index"
					:board="board"
				>
          <KanbanList :board="board" :use_pagination="true" @open_drawer_request="open_drawer_request"/>
				</KanbanBoard>
			</draggable>

		</div>
    <client-and-request ref="refClientAndRequest" :title="$t('add_request_text')"/>
<!--    <RequestShowDrawer ref="refRequestShowDrawer"/>-->
  </div>
</template>

<script>

	import draggable from "vuedraggable";
	import KanbanBoard from "./KanbanBoard";
	import KanbanTask from "./KanbanTask";
  import {mapActions, mapGetters, mapMutations} from "vuex";
  import KanbanList from "@/pages/Kanban/KanbanList";
  import RequestShowDrawer from "@/pages/AddClient/Request/RequestShowDrawer";
  import ClientAndRequest from "@/pages/AddClient/ClientAndRequest/ClientAndRequest";
  import OpenDrawerRequestMixin from "@/pages/AddClient/RequestMixins/OpenDrawerRequestMixin";
  import RoleMixin from "@/Mixins/RoleMixin";

	export default {
		props: {
			items: Array,
		},
		components: {
      KanbanList,
			draggable,
			KanbanBoard,
			KanbanTask,
      RequestShowDrawer,
      ClientAndRequest
		},
		data() {
			return {

				// Form object to update tasks.
      			updateTaskForm: this.$form.createForm(this, { name: 'update_task' }),

				// Form object to create boards.
      			newBoardForm: this.$form.createForm(this, { name: 'new_board' }),

				// Whether to show edit task modal.
      			showTaskFormModal: false,

				// Whether to show new board modal.
      			showBoardFormModal: false,

				// board_id of the task being edited.
      			editableBoardId: null,

				// Id of the task being edited.
      			editableTaskId: null,

				// Kanban data.
			}
		},
    computed:{
      ...mapGetters({
        GET_STATUSES:'kanban/GET_STATUSES',
      }),
    },
    async mounted() {
      await this.STATUS(this.$route.query)

    },
    destroyed() {
      this.SET_CLEAR()
    },
    mixins:[
      OpenDrawerRequestMixin,
      RoleMixin,
    ],
    methods: {
      add_request(){
        this.$refs.refClientAndRequest.visible = true
        this.$refs.refClientAndRequest.add_request = true
      },
      ...mapMutations({
        SET_CLEAR:'kanban/SET_CLEAR',
      }),
      ...mapActions({
        STATUS:'kanban/STATUS',
      }),
      handleScroll() {
        const container = this.$refs.scrollContainer;
        if (container.scrollTop + container.clientHeight >= container.scrollHeight) {
          console.log('adsadsadsadsads')
          // this.loadMore();
        }
      },

			// Add new task to a board.
			addNewTask( board_id, newTaskFields ) {
				for( let i = 0 ; i < this.boards.length ; i++ ) {
					const board = this.boards[ i ] ;

					if( board.id == board_id ) {
						board.tasks.push( newTaskFields ) ;
					}
				}
			},

			// update a task.
			updateTask( board_id, task_id, updatedTaskFields ) {
				let board = this.boards.filter( (board) => {
					return board.id == board_id ;
				})[0] ;

				let task = board.tasks.filter( (task) => {
					return task.id == task_id ;
				})[0] ;

				for( const key in updatedTaskFields ) {
					task[ key ] = updatedTaskFields[ key ] ;
				}

			},

			// Place task information on the form for editing and show the modal.
			showEditModal( task_id, board_id ) {

				this.editableBoardId = board_id ;
				this.editableTaskId = task_id ;

				let board = this.boards.filter( (board) => {
					return board.id == board_id ;
				})[0] ;

				let task = board.tasks.filter( (task) => {
					return task.id == task_id ;
				})[0] ;

      			this.showTaskFormModal = true;

				this.updateTaskForm.setFieldsValue({
					title: task.title,
					assignee: task.assignee,
					description: task.description,
				}) ;
			},

			// Event handler for the task-edit form submission.
			handleTaskUpdateSubmit(e) {
				this.updateTaskForm.validateFields( (err, values) => {
					if ( !err ) {
						this.updateTask( this.editableBoardId, this.editableTaskId, values )

						this.showTaskFormModal = false;
						this.updateTaskForm.resetFields() ;
					}
				});
			},

			// Event handler for the task-delete button click.
			deleteCurrentTask(e) {
				let board = this.boards.filter( (board) => {
					return board.id == this.editableBoardId ;
				})[0] ;

				board.tasks = board.tasks.filter( (task) => task.id != this.editableTaskId ) ;

				this.showTaskFormModal = false;
				this.updateTaskForm.resetFields() ;
			},

			// Event handler for the new-board form submission.
			handleAddNewBoardSubmit(e) {
				this.newBoardForm.validateFields( (err, values) => {
					if ( !err ) {
						this.addNewBoard( values )

						this.showBoardFormModal = false;
						this.newBoardForm.resetFields() ;
					}
				});
			},

			// Add new board to a kanban data.
			addNewBoard( newBoardFields ) {
				this.boards.push( {
					id: newBoardFields["title"].replace(' ', '-'),
					title: newBoardFields["title"],
					tasks: [],
				} ) ;
			},

		},
	}

</script>

<style lang="scss">
.kanban-head {
  display: flex;
  align-items: center;

  >div {
    padding: 0 25px;
    position: relative;

    &:first-child::before {
      content: '';
      position: absolute;
      background-color: #333333;
      opacity: .2;
      width: 1px;
      right: 0;
      top: 10px;
      bottom: 10px;
      margin: auto;
    }
  }
}

.kanban {
  overflow: auto;

  img {
    max-width: 100%;
  }
}

.kanban-board {
  min-width: 450px;
  box-shadow: none;
  background: #e9ecef;
  margin-right: 20px;

  >.ant-card-body {
    padding-bottom: 30px;
  }

  //.ant-form {
  //  textarea {
  //    box-shadow: $shadow-1;
  //  }
  //}
}

.kanban-boards {
  display: flex;
  align-items: flex-start;
}

.kanban-card {
  img {
    border-radius: 4px;
  }
}
</style>
